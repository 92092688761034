import React from 'react';
import LayoutWrapper from '../components/LayoutWrapper/LayoutWrapper';
import design from '../config/design';
import { useStaticQuery, graphql } from 'gatsby';
import Title from '../components/Title/Title';
import { View } from '../components/View/View';
import { Visible } from '../components/Visible/Visible';
import YouTube from 'react-youtube';

import Form from '../components/Form/Form';
import { BigView } from '../components/BigView/BigView';

function JoinLinkbox() {
  const _onReady = event => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  const { joinUs, productsInCampaign } = useStaticQuery(
    graphql`
      query {
        joinUs: datoCmsJoinLinkbox {
          title
          titleForm
          youtubeVideoId
        }
        productsInCampaign: allDatoCmsProduct(
          filter: { isActiveInCampaign: { eq: true } }
        ) {
          nodes {
            productName
          }
        }
      }
    `
  );

  const join = joinUs;
  const products = productsInCampaign;

  return (
    <>
      <LayoutWrapper>
        <BigView>
          <View
            top={3.5}
            desktopLeft={6}
            desktopRight={6}
            mobileLeft={1}
            mobileRight={1}
            tabletLeft={3}
            tabletRight={3}
            bottom={2}
          >
            <Title color={design.colors.blue150} content={join.title} />
            <View top={2} />
            <Visible mobile tablet>
              <YouTube
                videoId={join.youtubeVideoId}
                opts={{ height: '193', width: '100%' }}
                onReady={_onReady}
              />
            </Visible>
            <Visible desktop>
              <YouTube
                videoId={join.youtubeVideoId}
                opts={{ height: '720', width: '100%' }}
                onReady={_onReady}
              />
            </Visible>

            <View top={3} center>
              <Title color={design.colors.blue150} content={join.titleForm} />
            </View>
            <View center top={2}>
              <Form products={products} />
            </View>

            <View top={5} />
          </View>
        </BigView>
      </LayoutWrapper>
    </>
  );
}

export default JoinLinkbox;
